import { createRouter, createWebHistory, createWebHashHistory } from 'vue-router';
import LoginLayout from '../layouts/LoginLayout.vue';
import MainLayout from '../layouts/MainLayout.vue';

const routes = [
    {
        name: 'root',
        path: '/:pathMatch(.*)*',
        beforeEnter(to, from) {
            return false;
        },
    },
];
const loginRoutes = [{ name: 'login', path: '/:pathMatch(.*)*', component: LoginLayout }];
const mainRoutes = [
    {
        name: 'root',
        path: '/',
        component: MainLayout,
        // prettier-ignore
        children: [
            { name: 'main', path: '/', component: () => import(/* webpackChunkName: "main" */ '../views/MainView.vue') },
            { name: 'profile', path: '/profile', component: () => import(/* webpackChunkName: "profile" */ '../views/ProfileView.vue') },
            { name: 'achievement', path: '/achievement', component: () => import(/* webpackChunkName: "achievement" */ '../views/Achievement/AchievementView.vue') },
            { name: 'achievement-detail', path: '/achievement/detail/:id', component: () => import(/* webpackChunkName: "achievement-detail" */ '../views/Achievement/AchievementDetailView.vue') },
            { name: 'achievement-edit', path: '/achievement/edit/:id?', component: () => import(/* webpackChunkName: "achievement-edit" */ '../views/Achievement/AchievementEditView.vue') },
            { name: 'achievement_category', path: '/achievement_category', component: () => import(/* webpackChunkName: "achievement_category" */ '../views/AchievementCategory/AchievementCategoryView.vue') },
            { name: 'achievement_category-detail', path: '/achievement_category/detail/:id', component: () => import(/* webpackChunkName: "achievement_category-detail" */ '../views/AchievementCategory/AchievementCategoryDetailView.vue') },
            { name: 'achievement_category-edit', path: '/achievement_category/edit/:id?', component: () => import(/* webpackChunkName: "achievement_category-edit" */ '../views/AchievementCategory/AchievementCategoryEditView.vue') },
            { name: 'article', path: '/article', component: () => import(/* webpackChunkName: "article" */ '../views/Article/ArticleView.vue') },
            { name: 'article-detail', path: '/article/detail/:id', component: () => import(/* webpackChunkName: "article-detail" */ '../views/Article/ArticleDetailView.vue') },
            { name: 'article-edit', path: '/article/edit/:id?', component: () => import(/* webpackChunkName: "article-edit" */ '../views/Article/ArticleEditView.vue') },
            { name: 'article-file-detail', path: '/article/file/detail/:articleId/:id', component: () => import(/* webpackChunkName: "article-file-detail" */ '../views/Article/File/FileDetailView.vue') },
            { name: 'article-file-edit', path: '/article/file/edit/:articleId/:id?', component: () => import(/* webpackChunkName: "article-file-edit" */ '../views/Article/File/FileEditView.vue') },
            { name: 'article-gallery_entry-detail', path: '/article/gallery_entry/detail/:articleId/:galleryId/:id', component: () => import(/* webpackChunkName: "article-gallery_entry-detail" */ '../views/Article/GalleryEntry/GalleryEntryDetailView.vue') },
            { name: 'article-gallery_entry-edit', path: '/article/gallery_entry/edit/:articleId/:galleryId/:id?', component: () => import(/* webpackChunkName: "article-gallery_entry-edit" */ '../views/Article/GalleryEntry/GalleryEntryEditView.vue') },
            { name: 'article_category', path: '/article_category', component: () => import(/* webpackChunkName: "article_category" */ '../views/ArticleCategory/ArticleCategoryView.vue') },
            { name: 'article_category-detail', path: '/article_category/detail/:id', component: () => import(/* webpackChunkName: "article_category-detail" */ '../views/ArticleCategory/ArticleCategoryDetailView.vue') },
            { name: 'article_category-edit', path: '/article_category/edit/:id?', component: () => import(/* webpackChunkName: "article_category-edit" */ '../views/ArticleCategory/ArticleCategoryEditView.vue') },
            { name: 'cloud_image', path: '/cloud_image', component: () => import(/* webpackChunkName: "cloud_image" */ '../views/CloudImage/CloudImageView.vue') },
            { name: 'cloud_image-detail', path: '/cloud_image/detail/:id', component: () => import(/* webpackChunkName: "cloud_image-detail" */ '../views/CloudImage/CloudImageDetailView.vue') },
            { name: 'cloud_image-edit', path: '/cloud_image/edit/:id?', component: () => import(/* webpackChunkName: "cloud_image-edit" */ '../views/CloudImage/CloudImageEditView.vue') },
            { name: 'diary', path: '/diary', component: () => import(/* webpackChunkName: "diary" */ '../views/Diary/DiaryView.vue') },
            { name: 'diary-detail', path: '/diary/detail/:id', component: () => import(/* webpackChunkName: "diary-detail" */ '../views/Diary/DiaryDetailView.vue') },
            { name: 'diary-edit', path: '/diary/edit/:id?', component: () => import(/* webpackChunkName: "diary-edit" */ '../views/Diary/DiaryEditView.vue') },
            { name: 'election', path: '/election', component: () => import(/* webpackChunkName: "election" */ '../views/Election/ElectionView.vue') },
            { name: 'election-detail', path: '/election/detail/:id', component: () => import(/* webpackChunkName: "election-detail" */ '../views/Election/ElectionDetailView.vue') },
            { name: 'election-edit', path: '/election/edit/:id?', component: () => import(/* webpackChunkName: "election-edit" */ '../views/Election/ElectionEditView.vue') },
            { name: 'election-election_zone-detail', path: '/election/election_zone/detail/:electionId/:id', component: () => import(/* webpackChunkName: "election-election_zone-detail" */ '../views/Election/ElectionZone/ElectionZoneDetailView.vue') },
            { name: 'election-election_zone-edit', path: '/election/election_zone/edit/:electionId/:id?', component: () => import(/* webpackChunkName: "election-election_zone-edit" */ '../views/Election/ElectionZone/ElectionZoneEditView.vue') },
            { name: 'election-election_member-detail', path: '/election/election_member/detail/:electionId/:id', component: () => import(/* webpackChunkName: "election-election_member-detail" */ '../views/Election/ElectionMember/ElectionMemberDetailView.vue') },
            { name: 'election-election_member-edit', path: '/election/election_member/edit/:electionId/:id?', component: () => import(/* webpackChunkName: "election-election_member-edit" */ '../views/Election/ElectionMember/ElectionMemberEditView.vue') },
            { name: 'election-election_report-detail', path: '/election/election_report/detail/:electionId/:id', component: () => import(/* webpackChunkName: "election-election_report-detail" */ '../views/Election/ElectionReport/ElectionReportDetailView.vue') },
            { name: 'election-election_candidate-detail', path: '/election/election_candidate/detail/:electionId/:id', component: () => import(/* webpackChunkName: "election-election_candidate-detail" */ '../views/Election/ElectionCandidate/ElectionCandidateDetailView.vue') },
            { name: 'election-election_candidate-edit', path: '/election/election_candidate/edit/:electionId/:id?', component: () => import(/* webpackChunkName: "election-election_candidate-edit" */ '../views/Election/ElectionCandidate/ElectionCandidateEditView.vue') },
            { name: 'election-election_result-detail', path: '/election/election_result/detail/:electionId/:id', component: () => import(/* webpackChunkName: "election-election_result-detail" */ '../views/Election/ElectionResult/ElectionResultDetailView.vue') },
            { name: 'election-election_result-edit', path: '/election/election_result/edit/:electionId/:id?', component: () => import(/* webpackChunkName: "election-election_result-edit" */ '../views/Election/ElectionResult/ElectionResultEditView.vue') },
            { name: 'election_member', path: '/election_member', component: () => import(/* webpackChunkName: "election_member" */ '../views/ElectionMember/ElectionMemberView.vue') },
            { name: 'election_member-detail', path: '/election_member/detail/:id', component: () => import(/* webpackChunkName: "election_member-detail" */ '../views/ElectionMember/ElectionMemberDetailView.vue') },
            { name: 'election_member_blocked', path: '/election_member_blocked', component: () => import(/* webpackChunkName: "election_member_blocked" */ '../views/ElectionMemberBlocked/ElectionMemberBlockedView.vue') },
            { name: 'election_member_blocked-detail', path: '/election_member_blocked/detail/:id', component: () => import(/* webpackChunkName: "election_member_blocked-detail" */ '../views/ElectionMemberBlocked/ElectionMemberBlockedDetailView.vue') },
            { name: 'election_member_blocked-edit', path: '/election_member_blocked/edit/:id?', component: () => import(/* webpackChunkName: "election_member_blocked-edit" */ '../views/ElectionMemberBlocked/ElectionMemberBlockedEditView.vue') },
            { name: 'enum_district', path: '/enum_district', component: () => import(/* webpackChunkName: "enum_district" */ '../views/EnumDistrict/EnumDistrictView.vue') },
            { name: 'enum_district-detail', path: '/enum_district/detail/:id', component: () => import(/* webpackChunkName: "enum_district-detail" */ '../views/EnumDistrict/EnumDistrictDetailView.vue') },
            { name: 'enum_district-edit', path: '/enum_district/edit/:id?', component: () => import(/* webpackChunkName: "enum_district-edit" */ '../views/EnumDistrict/EnumDistrictEditView.vue') },
            { name: 'enum_function', path: '/enum_function', component: () => import(/* webpackChunkName: "enum_function" */ '../views/EnumFunction/EnumFunctionView.vue') },
            { name: 'enum_function-detail', path: '/enum_function/detail/:id', component: () => import(/* webpackChunkName: "enum_function-detail" */ '../views/EnumFunction/EnumFunctionDetailView.vue') },
            { name: 'enum_function-edit', path: '/enum_function/edit/:id?', component: () => import(/* webpackChunkName: "enum_function-edit" */ '../views/EnumFunction/EnumFunctionEditView.vue') },
            { name: 'enum_function_group', path: '/enum_function_group', component: () => import(/* webpackChunkName: "enum_function_group" */ '../views/EnumFunctionGroup/EnumFunctionGroupView.vue') },
            { name: 'enum_function_group-detail', path: '/enum_function_group/detail/:id', component: () => import(/* webpackChunkName: "enum_function_group-detail" */ '../views/EnumFunctionGroup/EnumFunctionGroupDetailView.vue') },
            { name: 'enum_function_group-edit', path: '/enum_function_group/edit/:id?', component: () => import(/* webpackChunkName: "enum_function_group-edit" */ '../views/EnumFunctionGroup/EnumFunctionGroupEditView.vue') },
            { name: 'enum_function_kind', path: '/enum_function_kind', component: () => import(/* webpackChunkName: "enum_function_kind" */ '../views/EnumFunctionKind/EnumFunctionKindView.vue') },
            { name: 'enum_function_kind-detail', path: '/enum_function_kind/detail/:id', component: () => import(/* webpackChunkName: "enum_function_kind-detail" */ '../views/EnumFunctionKind/EnumFunctionKindDetailView.vue') },
            { name: 'enum_function_kind-edit', path: '/enum_function_kind/edit/:id?', component: () => import(/* webpackChunkName: "enum_function_kind-edit" */ '../views/EnumFunctionKind/EnumFunctionKindEditView.vue') },
            { name: 'enum_region', path: '/enum_region', component: () => import(/* webpackChunkName: "enum_region" */ '../views/EnumRegion/EnumRegionView.vue') },
            { name: 'enum_region-detail', path: '/enum_region/detail/:id', component: () => import(/* webpackChunkName: "enum_region-detail" */ '../views/EnumRegion/EnumRegionDetailView.vue') },
            { name: 'enum_region-edit', path: '/enum_region/edit/:id?', component: () => import(/* webpackChunkName: "enum_region-edit" */ '../views/EnumRegion/EnumRegionEditView.vue') },
            { name: 'enum_town', path: '/enum_town', component: () => import(/* webpackChunkName: "enum_town" */ '../views/EnumTown/EnumTownView.vue') },
            { name: 'enum_town-detail', path: '/enum_town/detail/:id', component: () => import(/* webpackChunkName: "enum_town-detail" */ '../views/EnumTown/EnumTownDetailView.vue') },
            { name: 'enum_town-edit', path: '/enum_town/edit/:id?', component: () => import(/* webpackChunkName: "enum_town-edit" */ '../views/EnumTown/EnumTownEditView.vue') },
            { name: 'event', path: '/event', component: () => import(/* webpackChunkName: "event" */ '../views/Event/EventView.vue') },
            { name: 'event-detail', path: '/event/detail/:id', component: () => import(/* webpackChunkName: "event-detail" */ '../views/Event/EventDetailView.vue') },
            { name: 'event-edit', path: '/event/edit/:id?', component: () => import(/* webpackChunkName: "event-edit" */ '../views/Event/EventEditView.vue') },
            { name: 'gallery', path: '/gallery', component: () => import(/* webpackChunkName: "gallery" */ '../views/Gallery/GalleryView.vue') },
            { name: 'gallery-detail', path: '/gallery/detail/:id', component: () => import(/* webpackChunkName: "gallery-detail" */ '../views/Gallery/GalleryDetailView.vue') },
            { name: 'gallery-edit', path: '/gallery/edit/:id?', component: () => import(/* webpackChunkName: "gallery-edit" */ '../views/Gallery/GalleryEditView.vue') },
            { name: 'gallery-gallery_entry-detail', path: '/gallery/gallery_entry/detail/:galleryId/:id', component: () => import(/* webpackChunkName: "gallery-gallery_entry-detail" */ '../views/Gallery/GalleryEntry/GalleryEntryDetailView.vue') },
            { name: 'gallery-gallery_entry-edit', path: '/gallery/gallery_entry/edit/:galleryId/:id?', component: () => import(/* webpackChunkName: "gallery-gallery_entry-edit" */ '../views/Gallery/GalleryEntry/GalleryEntryEditView.vue') },
            { name: 'mail', path: '/mail', component: () => import(/* webpackChunkName: "mail" */ '../views/Mail/MailView.vue') },
            { name: 'mail-detail', path: '/mail/detail/:id', component: () => import(/* webpackChunkName: "mail-detail" */ '../views/Mail/MailDetailView.vue') },
            { name: 'mail_pattern', path: '/mail_pattern', component: () => import(/* webpackChunkName: "mail_pattern" */ '../views/MailPattern/MailPatternView.vue') },
            { name: 'mail_pattern-detail', path: '/mail_pattern/detail/:id', component: () => import(/* webpackChunkName: "mail_pattern-detail" */ '../views/MailPattern/MailPatternDetailView.vue') },
            { name: 'mail_pattern-edit', path: '/mail_pattern/edit/:id?', component: () => import(/* webpackChunkName: "mail_pattern-edit" */ '../views/MailPattern/MailPatternEditView.vue') },
            { name: 'menu_item', path: '/menu_item', component: () => import(/* webpackChunkName: "menu_item" */ '../views/MenuItem/MenuItemView.vue') },
            { name: 'menu_item-detail', path: '/menu_item/detail/:id', component: () => import(/* webpackChunkName: "menu_item-detail" */ '../views/MenuItem/MenuItemDetailView.vue') },
            { name: 'menu_item-edit', path: '/menu_item/edit/:id?', component: () => import(/* webpackChunkName: "menu_item-edit" */ '../views/MenuItem/MenuItemEditView.vue') },
            { name: 'office', path: '/office', component: () => import(/* webpackChunkName: "office" */ '../views/Office/OfficeView.vue') },
            { name: 'office-detail', path: '/office/detail/:id', component: () => import(/* webpackChunkName: "office-detail" */ '../views/Office/OfficeDetailView.vue') },
            { name: 'office-edit', path: '/office/edit/:id?', component: () => import(/* webpackChunkName: "office-edit" */ '../views/Office/OfficeEditView.vue') },
            { name: 'person', path: '/person', component: () => import(/* webpackChunkName: "person" */ '../views/Person/PersonView.vue') },
            { name: 'person-detail', path: '/person/detail/:id', component: () => import(/* webpackChunkName: "person-detail" */ '../views/Person/PersonDetailView.vue') },
            { name: 'person-edit', path: '/person/edit/:id?', component: () => import(/* webpackChunkName: "person-edit" */ '../views/Person/PersonEditView.vue') },
            { name: 'person-person_article-detail', path: '/person/person_article/detail/:personId/:id', component: () => import(/* webpackChunkName: "person-person_article-detail" */ '../views/Person/PersonArticle/PersonArticleDetailView.vue') },
            { name: 'person-person_article-edit', path: '/person/person_article/edit/:personId/:id?', component: () => import(/* webpackChunkName: "person-person_article-edit" */ '../views/Person/PersonArticle/PersonArticleEditView.vue') },
            { name: 'person-file-detail', path: '/person/file/detail/:personId/:id', component: () => import(/* webpackChunkName: "person-file-detail" */ '../views/Person/File/FileDetailView.vue') },
            { name: 'person-file-edit', path: '/person/file/edit/:personId/:id?', component: () => import(/* webpackChunkName: "person-file-edit" */ '../views/Person/File/FileEditView.vue') },
            { name: 'person-gallery_entry-detail', path: '/person/gallery_entry/detail/:personId/:galleryId/:id', component: () => import(/* webpackChunkName: "person-gallery_entry-detail" */ '../views/Person/GalleryEntry/GalleryEntryDetailView.vue') },
            { name: 'person-gallery_entry-edit', path: '/person/gallery_entry/edit/:personId/:galleryId/:id?', component: () => import(/* webpackChunkName: "person-gallery_entry-edit" */ '../views/Person/GalleryEntry/GalleryEntryEditView.vue') },
            { name: 'print_pattern', path: '/print_pattern', component: () => import(/* webpackChunkName: "print_pattern" */ '../views/PrintPattern/PrintPatternView.vue') },
            { name: 'print_pattern-detail', path: '/print_pattern/detail/:id', component: () => import(/* webpackChunkName: "print_pattern-detail" */ '../views/PrintPattern/PrintPatternDetailView.vue') },
            { name: 'print_pattern-edit', path: '/print_pattern/edit/:id?', component: () => import(/* webpackChunkName: "print_pattern-edit" */ '../views/PrintPattern/PrintPatternEditView.vue') },
            { name: 'regional_activity', path: '/regional_activity', component: () => import(/* webpackChunkName: "regional_activity" */ '../views/RegionalActivity/RegionalActivityView.vue') },
            { name: 'regional_activity-detail', path: '/regional_activity/detail/:id', component: () => import(/* webpackChunkName: "regional_activity-detail" */ '../views/RegionalActivity/RegionalActivityDetailView.vue') },
            { name: 'regional_activity-edit', path: '/regional_activity/edit/:id?', component: () => import(/* webpackChunkName: "regional_activity-edit" */ '../views/RegionalActivity/RegionalActivityEditView.vue') },
            { name: 'regional_activity-file-detail', path: '/regional_activity/file/detail/:regionalActivityId/:id', component: () => import(/* webpackChunkName: "regional_activity-file-detail" */ '../views/RegionalActivity/File/FileDetailView.vue') },
            { name: 'regional_activity-file-edit', path: '/regional_activity/file/edit/:regionalActivityId/:id?', component: () => import(/* webpackChunkName: "regional_activity-file-edit" */ '../views/RegionalActivity/File/FileEditView.vue') },
            { name: 'regional_activity-gallery_entry-detail', path: '/regional_activity/gallery_entry/detail/:regionalActivityId/:galleryId/:id', component: () => import(/* webpackChunkName: "regional_activity-gallery_entry-detail" */ '../views/RegionalActivity/GalleryEntry/GalleryEntryDetailView.vue') },
            { name: 'regional_activity-gallery_entry-edit', path: '/regional_activity/gallery_entry/edit/:regionalActivityId/:galleryId/:id?', component: () => import(/* webpackChunkName: "regional_activity-gallery_entry-edit" */ '../views/RegionalActivity/GalleryEntry/GalleryEntryEditView.vue') },
            { name: 'regional_activity-regional_activity_history-detail', path: '/regional_activity/regional_activity_history/detail/:regionalActivityId/:id', component: () => import(/* webpackChunkName: "regional_activity-regional_activity_history-detail" */ '../views/RegionalActivity/RegionalActivityHistory/RegionalActivityHistoryDetailView.vue') },
            { name: 'setting', path: '/setting', redirect: '/setting/detail' },
            { name: 'setting-detail', path: '/setting/detail', component: () => import(/* webpackChunkName: "setting-detail" */ '../views/Setting/SettingDetailView.vue') },
            { name: 'setting-edit', path: '/setting/edit', component: () => import(/* webpackChunkName: "setting-edit" */ '../views/Setting/SettingEditView.vue') },
            { name: 'sticker', path: '/sticker', component: () => import(/* webpackChunkName: "sticker" */ '../views/Sticker/StickerView.vue') },
            { name: 'sticker-detail', path: '/sticker/detail/:id', component: () => import(/* webpackChunkName: "sticker-detail" */ '../views/Sticker/StickerDetailView.vue') },
            { name: 'sticker-edit', path: '/sticker/edit/:id?', component: () => import(/* webpackChunkName: "sticker-edit" */ '../views/Sticker/StickerEditView.vue') },
            { name: 'subpage', path: '/subpage', component: () => import(/* webpackChunkName: "subpage" */ '../views/Subpage/SubpageView.vue') },
            { name: 'subpage-detail', path: '/subpage/detail/:id', component: () => import(/* webpackChunkName: "subpage-detail" */ '../views/Subpage/SubpageDetailView.vue') },
            { name: 'subpage-edit', path: '/subpage/edit/:id?', component: () => import(/* webpackChunkName: "subpage-edit" */ '../views/Subpage/SubpageEditView.vue') },
            { name: 'subpage-image-detail', path: '/subpage/image/detail/:subpageId/:id', component: () => import(/* webpackChunkName: "subpage-image-detail" */ '../views/Subpage/Image/ImageDetailView.vue') },
            { name: 'subpage-image-edit', path: '/subpage/image/edit/:subpageId/:id?', component: () => import(/* webpackChunkName: "subpage-image-edit" */ '../views/Subpage/Image/ImageEditView.vue') },
            { name: 'user', path: '/user', component: () => import(/* webpackChunkName: "user" */ '../views/User/UserView.vue') },
            { name: 'user-detail', path: '/user/detail/:id', component: () => import(/* webpackChunkName: "user-detail" */ '../views/User/UserDetailView.vue') },
            { name: 'user-edit', path: '/user/edit/:id?', component: () => import(/* webpackChunkName: "user-edit" */ '../views/User/UserEditView.vue') },
        ],
    },
    { name: 'catch', path: '/:catchAll(.*)*', redirect: '/' },
];

const router = createRouter({
    history: process.env.VUE_APP_ROUTER_MODE == 'hash' ? createWebHashHistory(process.env.BASE_URL) : createWebHistory(process.env.BASE_URL),
    routes,
});
router.beforeEach((to, from, next) => {
    // console.log('beforeEach', from, to);
    // return false;
    if (to.meta && to.meta.persistent && !!Object.keys(from.query).length) {
        let success = false;
        for (let i = 0; i < to.meta.persistent.length; i++) {
            const persistent = to.meta.persistent[i];
            if (from.query[persistent] && !to.query[persistent]) {
                to.query[persistent] = from.query[persistent];
                success = true;
            }
        }
        if (success) {
            next(to);
        } else {
            next();
        }
    } else {
        next();
    }
});
router.beforeResolve((to, from) => {
    // console.log('beforeResolve', from, to);
    // return false;
});
router.afterEach((to, from) => {
    // console.log('afterEach', from, to);
    // return false;
});

router.addRoutes = function (routes) {
    router.options.routes = routes;
    routes.forEach((route) => {
        router.addRoute(route);
    });
};
router.removeRoutes = function () {
    router.options.routes.forEach((route) => {
        router.removeRoute(route.name);
    });
    router.options.routes = [];
};
router.reload = function () {
    const path = window.location.href.replace(window.location.origin + router.options.history.base, '');
    router.replace(router.resolve(path));
};
router.login = function () {
    router.removeRoutes();
    router.addRoutes(mainRoutes);
    router.reload();
};
router.logout = function () {
    router.removeRoutes();
    router.addRoutes(loginRoutes);
    router.reload();
};

export default router;

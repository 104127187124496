import Platform from 'quasar/src/plugins/Platform.js';;
import './skin.scss';

const gridLoadType = 'dynamic';

const commonColor = 'grey-7';
const commonDetailField = {
    filled: true,
    color: commonColor,
    textColor: 'grey-9',
};
const commonEditField = {
    filled: true,
    color: commonColor,
    hideBottomSpace: true,
};
const commonTable = {
    color: commonColor,
    loadType: gridLoadType || 'static',
    loadTypeEditable: true,
    selection: 'single',
    separator: 'cell',
    showRowCount: true,
    stickyHeader: true,
    stickyColumn: true,
    tableFilter: true,
    fullscreenToggle: true,
    selectOnRowClick: true,
    booleanAsCheckbox: true,
    columnsMinWidth: 50,
    columnsFilter: true,
    columnsResize: true,
    columnsResizeToFit: true,
    columnsReorder: true,
    columnsControl: true,
    reload: true,
    toolbarButtonsProps: { unelevated: true, color: commonColor, textColor: 'white' },
};

export default {
    e_color: commonColor,
    e_login_page: {
        class: 'e-login-page',
    },
    e_login_card: {
        class: 'e-login-card',
    },
    e_login_input: {
        filled: commonEditField.filled,
        color: commonEditField.color,
        labelColor: 'grey-6',
        hideBottomSpace: true,
        class: 'e-login-input autofill-fix',
    },
    e_login_button: {
        class: 'e-login-button e-login',
    },
    e_main_header: {
        class: 'e-header',
    },
    e_main_toolbar: {
        class: 'e-mainToolbar text-grey-4',
    },
    e_main_toolbar_button: {
        class: 'e-mainToolbar-button text-grey-4 q-mx-sm',
    },
    e_main_toolbar_popup: {
        class: 'e-mainToolbar-popup text-grey-4',
    },
    e_main_toolbar_popup_card: {
        class: 'e-mainToolbar-popup-card',
    },
    e_main_toolbar_popup_list: {
        class: 'e-mainToolbar-popup-list',
    },
    e_main_toolbar_popup_list_item: {
        class: 'e-mainToolbar-popup-listItem',
    },
    e_main_toolbar_popup_list_checkbox: {
        class: 'e-mainToolbar-popup-list-checkbox',
    },
    e_main_toolbar_popup_list_toggle: {
        class: 'e_main_toolbar_popup_list_toggle',
    },
    e_main_drawer: {
        bordered: false,
        'no-swipe-open': true,
        class: 'e-mainDrawer text-grey-5',
    },
    e_main_drawer_profileList: {
        class: 'e-mainDrawer-profileList',
    },
    e_main_drawer_profileList_item: {
        class: 'e-mainDrawer-profileList-item',
    },
    e_main_drawer_menuList: {
        class: 'e-mainDrawer-menuList text-grey-5',
    },
    e_main_drawer_menuList_item: {
        class: 'e-mainDrawer-menuList-item',
    },
    e_main_drawer_menuList_expansionItem: {
        class: 'e-mainDrawer-menuList-item expansion',
    },
    e_main_drawer_footer: {
        class: 'e-mainDrawer-footer',
    },
    e_main_pageContainer: {
        class: 'e-pageContainer',
    },
    e_profile_page: {
        class: 'e-profile',
    },
    e_profile_card: {
        class: 'e-profile-card',
    },
    e_page_tableView: {
        class: 'e-tableView',
    },
    e_tabs: {
        class: 'e-tabs text-grey-4',
        'active-class': 'text-bold text-grey-9 bg-white',
    },
    e_tab: {
        class: 'e-tabs-tab',
    },
    e_tabPanels: {
        class: 'e-tabs-tabPanels',
    },
    e_tabPanel_tableView: {
        class: 'e-tabs-tabPanels-item tableView',
    },
    e_card: {
        class: 'e-card',
    },
    e_card_toolbar: {
        class: 'e-card-toolbar',
    },
    e_card_button_add: {
        class: 'e-card-buttons add',
    },
    e_card_button_remove: {
        class: 'e-card-buttons remove',
    },
    e_gallery: {
        class: 'e-gallery',
    },
    e_gallery_img_vid: {
        class: 'e_gallery_img_vid',
    },
    e_table: {
        ...commonTable,
        'toolbar-props': {
            'menu-button-props': {
                class: 'e-table-overflowPopupButton',
            },
        },
        class: {
            'e-table': true,
            safariFix: Platform.is.safari,
        },
        toolbarSearchInputProps: {
            class: 'e-table-searchInput',
        },
        toolbarButtonsProps: {
            class: 'e-table-configButton',
        },
    },
    e_toolbar: {
        class: 'e-toolbar bg-white',
    },
    e_toolbar_button: {
        class: 'e-toolBar-button text-grey-4',
    },
    e_detailField: {
        ...commonDetailField,
        class: 'e-detail',
    },
    e_input: {
        ...commonEditField,
        class: 'e-input',
    },
    e_input_textarea: {
        ...commonEditField,
        inputStyle: 'resize: none',
        class: 'e-input',
    },
    e_dateTimeInput: {
        ...commonEditField,
        class: 'e-input',
        popupProxyProps: { class: 'e-date-time' },
    },
    e_checkbox: {
        color: commonEditField.color,
        class: 'e-checkbox',
    },
    e_toolbar_checkbox: {
        color: commonEditField.color,
        class: 'e-toolbar-checkbox',
    },
    e_select: {
        ...commonEditField,
        class: 'e-input',
        'popup-content-class': 'e-select-menu',
    },
    e_chooseInput: {
        ...commonEditField,
        gridLoadType: gridLoadType || 'static',
        class: 'e-input e-choose',
        chooseOnClick: true,
        'grid-toolbar-props': {
            'menu-button-props': {
                class: 'e-table-overflowPopupButton',
            },
        },
    },
    e_fileInput_multi: {
        ...commonEditField,
        class: 'e-input e-miltifile',
    },
    e_tooltip: {
        class: 'e-tooltip',
    },
};

import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import i18n from './i18n';
import Quasar from 'quasar/src/vue-plugin.js';;
import quasarUserOptions from './quasar-user-options';
import { EnfisQuasar } from '@enfis/quasar';
import skin from './styles/skin/skin.js';

createApp(App)
    .use(Quasar, quasarUserOptions)
    .use(i18n)
    .use(store)
    .use(router)
    .use(EnfisQuasar, {
        config: {
            io: { baseURL: window.config.enfisBaseUrl },
            user: {
                roles: {
                    ROLE_USERS_MANAGER: 1,
                    ROLE_SETTINGS_MANAGER: 2,
                    ROLE_MAIL_MANAGER: 3,
                    ROLE_MAIL_PATTERN_MANAGER: 4,
                    ROLE_PRINT_PATTERN_MANAGER: 5,
                    ROLE_SUBPAGE_MANAGER: 6,
                    ROLE_ENUMS_MANAGER: 7,
                    ROLE_ENUMS_TOWNS_MANAGER: 8,
                    ROLE_PERSONS_MANAGER: 9,
                    ROLE_ARTICLES_MANAGER: 10,
                    ROLE_ACHIEVEMENTS_MANAGER: 11,
                    ROLE_GALLERY_MANAGER: 12,
                    ROLE_DIARY_MANAGER: 13,
                    ROLE_EVENTS_MANAGER: 14,
                    ROLE_OFFICE_MANAGER: 15,
                    ROLE_REGIONAL_ACTIVITY_ADMIN: 16,
                    ROLE_REGIONAL_ACTIVITY_USER: 17,
                    ROLE_REGIONAL_ACTIVITY_READER: 18,
                    ROLE_ELECTION_ADMIN: 19,
                    ROLE_ELECTION_USER: 20,
                    ROLE_STICKERS_MANAGER: 21,
                    ROLE_PERSON_FUNCTION_MANAGER: 100,
                },
            },
            loading: {
                spinnerColor: skin.e_color,
            },
            props: {
                ...skin,
            },
        },
    })
    .mount('#app');

import { createStore } from 'vuex';

export default createStore({
    state: {
        appInfo: null,
        appTitle: null,
        appBack: null,
        appSettingsStore: false,
    },
    getters: {},
    mutations: {
        setAppInfo(state, appInfo) {
            state.appInfo = appInfo;
        },
        setAppTitle(state, appTitle) {
            state.appTitle = appTitle;
        },
        setAppBack(state, appBack) {
            state.appBack = appBack;
        },
        setAppSettingsStore(state, appSettingsStore) {
            state.appSettingsStore = appSettingsStore;
        },
    },
    actions: {},
    modules: {},
});
